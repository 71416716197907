.docter_details_header {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.docter_image_container {
  height: 300px;
  width: 220px;
  background-color: #9fd7ef;
  border-top-right-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.docter_image_container img {
  max-width: 100%;
}

.docter_detials_chips p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
.docter_detials_chips {
  background-size: contain;
  height: 90px;
  width: 95px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 12px 0;
  background: url("../../assets/images/docter_chips.png") no-repeat;
}
.docter_details {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.docter_typos h1 {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.docter_typos p {
  font-weight: 500;
  font-size: 16px;
  color: #505050;
  margin-bottom: 5px;
}
.km_chip {
  background: #00bf91;
  border-radius: 6.5641px;
  padding: 5px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
}
.fees_chip {
  background: #009dbf;
  border-radius: 6.66667px;
  padding: 5px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  margin-top: 15px;
}
.fees_chip svg {
  margin-right: 5px;
}
.docter_about {
  padding: 10px 20px;
}
.docter_about h2 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.docter_about p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #4e5674;
}
.docter_specialities {
  padding: 10px 20px;
}
.docter_specialities h2 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.docter_specilaities_chips {
  display: flex;
  flex-wrap: wrap;
}
.docter_specilaities_chips div {
  background: #f7f8f9;
  border-radius: 1.05145px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #5a6472;
  padding: 5px;
  margin: 8px;
}
.docter_education {
  padding: 10px 20px;
}
.docter_education h2 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.docter_education ul ol {
  margin: 0;
  padding: 0;
}
.docter_education ul li {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7d818b;
  margin: 10px 0;
}
.docter_address {
  padding: 10px 20px;
}
.docter_address svg {
  margin-right: 5px;
}
.docter_address h2 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.docter_address p {
  font-weight: 400;
  font-size: 12px;
  color: #4e5674;
  margin-bottom: 5px;
}
.docter_address h5 {
  font-weight: 400;
  font-size: 13px;
  color: #48484b;
}
.book_appointment_button button {
  background: #194af5;
  border-radius: 6px;
  border: none;
  padding: 8px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  width: 100%;
}
.book_appointment_button {
  width: 90%;
  margin: 20px auto;
}
