.abdm_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 30px 20px;
  background-color: #e8eefe;
  border-bottom-left-radius: 60% 200px !important;
  border-bottom-right-radius: 60% 200px !important;
  /* background-size: 100%; */
  /* background-position: center; */
  /* background: url("../../../assets//images/Ellipse\ 5598.svg") no-repeat; */
}
.abdm-back-logo {
  position: absolute;
  top: 5%;
  left: 8%;
}
.abdm_header h1 {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #003bd2;
  margin: 10px 0;
}
.abdm_header img {
  max-width: 90%;
}
.abdm_content {
  width: 90%;
  margin: 30px auto;
}
.abdm_content h1 {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #003bd2;
}
.abdm_content p {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #b7b7b7;
  margin-bottom: 50px;
}
.abdm_content label {
  font-weight: 500;
  font-size: 14px;
  line-height: 11px;
  letter-spacing: 0.04em;
  color: #232526;
  display: block;
  margin-bottom: 15px;
}
.abdm_content button {
  background: #194af5;
  border: 1px solid #e6ebed;
  border-radius: 4px;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  padding: 6px;
  width: 100%;
  margin-bottom: 20px;
}
.abdm_number_input {
  background: #ffffff;
  border: 1px solid #e6ebed;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  padding: 8px;
  margin-bottom: 20px;
  width: 100%;
}
.abdm_number_input::placeholder {
  color: #7d818b;
  font-size: 14px;
}
