/* The booking_type_label */
.booking_for_container {
  width: 90%;
  margin: auto;
  margin-bottom: 30px;
}
.booking_type_label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.booking_type_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.booking_type_label_check {
  position: absolute;
  top: 5px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.booking_type_label_not_check {
  position: absolute;
  top: 5px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/* On mouse-over, add a grey background color */
.booking_type_label:hover input ~ .booking_type_label_check {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.booking_type_label input:checked ~ .booking_type_label_check {
  background-color: #194af5;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.booking_type_label_check:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.booking_type_label input:checked ~ .booking_type_label_check:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.booking_type_label .booking_type_label_check:after {
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  background: #fff;
}
