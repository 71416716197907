.PrescriptionPDF_box {
  background-color: #fff;
  font-size: 16px;
  height: auto;
}
.prescription_clinic_box {
  background: #e6ebed;
  padding: 20px;
}

.prescription_clinic_inner_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prescription_clinic_inner_box div {
  width: 100%;
}
.prescription_clinic_inner_box h3 {
  font-weight: 600;
  font-size: 1em;
  color: #000000;
  margin-bottom: 1em;
}
.prescription_clinic_inner_box h4 {
  font-weight: 500;
  font-size: 1em;
  color: #000000;
  margin-bottom: 1em;
}
.prescription_clinic_inner_box h5 {
  font-weight: 400;
  font-size: 0.9em;
  color: #000000;
}
.prescription_clinic_inner_box p {
  font-weight: 400;
  font-weight: 500;
  font-size: 0.8em;
  color: #000000;
  margin-bottom: 0.5em;
}
.prescription_clinic_inner_box h6 {
  font-weight: 400;
  font-size: 0.8em;
  font-weight: 500;
  color: #000000;
}
.prescription_patient_container {
  border-bottom: 1px solid #b7b7b7;
}
.prescription_patient_box {
  width: 100%;
  padding: 30px 20px;
}
.prescription_patient_box h2 {
  font-weight: 600;
  font-size: 1em;
  color: #000000;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}
.prescription_patient_box_name {
  font-weight: 500;
  font-size: 0.9em;
  color: #000000;
  margin-bottom: 1em;
}
.patient_prescription_content {
  border-bottom: 1px solid #b7b7b7;
}
.prescription_item {
  padding: 20px;
}
.prescription_item h1 {
  font-weight: 500;
  font-size: 1.2rem;
  color: #000000;
  margin-bottom: 1.2rem;
}
.prescription_item p {
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
}
.prescription_test_container {
  border-bottom: 1px solid #b7b7b7;
}
.prescription_test_item {
  padding: 30px 20px;
}
.prescription_test_item h1 {
  font-weight: 500;
  font-size: 1.2rem;
  color: #000000;
  margin-bottom: 1.2rem;
}
.prescription_test_item ol li {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 1rem;
}
.prescription_drugs_table {
  border-bottom: 1px solid #b7b7b7;
  padding: 20px 0;
}
.prescription_drugs_table table {
  border-collapse: collapse;
  width: 100%;
  // margin: auto;
}

.prescription_drugs_table td,
.prescription_drugs_table th {
  border: 0.693676px solid #d6d6d6;
  text-align: left;
  padding: 1em 0.4em;
  font-size: 0.7em;
}
.prescription_drugs_table thead tr th {
  font-weight: 500;
  font-size: 0.8em;
  color: #000000;
  background: rgba(238, 242, 255, 0.72);
  border: 0.693676px solid #d6d6d6;
}
.prescription_drugs_table tr:nth-child(even) {
  background-color: #fff;
}
.prescription_drugs_table thead tr th:first-child {
  width: 30%;
}
.prescription_follow_update {
  border-bottom: 1px solid #b7b7b7;
}
.prescription_follow_update_wrapper {
  padding: 30px 20px;
}

.prescription_follow_update_wrapper h1 {
  font-weight: 500;
  font-size: 1em;
  color: #000000;
  margin-bottom: 1em;
}
.prescription_follow_update_wrapper p {
  font-weight: 400;
  font-size: 1em;
  color: #000000;
}
