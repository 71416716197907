.CreateProfileForm_logo {
  width: 100%;
  text-align: center;
}
.CreateProfileForm_logo img {
  width: 90px;
  margin: 20px 0;
}
.CreateProfileForm_container {
  width: 90%;
  margin: auto;
}
.CreateProfileForm_container h1 {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #838383;
  margin-bottom: 30px;
}
.CreateProfileForm_container label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #2d2d2d;
}
.createProfile_input input {
  width: 100%;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 6px;
}
.createProfile_input input::placeholder {
  font-size: 12px;
  color: #838383;
}
.createProfile_input input:focus {
  border: 1px solid #194af5;
}
.CreateProfileForm_container button {
  background: #194af5;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  padding: 6px;
  width: 100%;
  border: none;
  margin-top: 20px;
}
