.closed_bookings_tabs {
  display: flex;
  justify-content: flex-start;
  width: 95%;
  margin: 1em auto;
}
.closed_bookings_tab {
  background: transparent;
  color: #000;
  text-align: center;
  padding: 5px 20px;
  font-size: 0.8em;
  position: relative;
}
.closed_bookings_tab.closed_tab_active::after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  height: 2px;
  width: 80%;
  background-color: #194af5;
}
