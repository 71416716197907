.BookDateNtime_card {
  background-color: #fbfbfb;
}
#BookDateNtimeHead {
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}
.CalanderNdate {
  font-weight: 400;
  font-size: 0.875rem;
  color: #48484b;
}
#DateDiv {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #48484b;
}
#TimeDiv {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #48484b;
}
#ChangeDateNtime {
  font-weight: 600;
  font-size: 12px;
  color: #194af5;
  margin-bottom: 10px;
}
