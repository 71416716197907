.carousel-container{
  width: 100%;
  height: 200px;
  margin-top: 10px;
}
.carousel-root,
.carousel-slider,
.slide,
.slider,
.slider-wrapper{
  height: 100%;
}
