.home_filter_overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
}
.home_filter {
  padding-bottom: 20vh;
}
.home_filter .home_filter_header{
  display: flex;
  align-items: center;
  min-width: 100%;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid gray;
  margin-bottom: 20px;
}
.home_filter h1 {
  font-weight: 500;
  font-size: 18px;
  color: #48484b;
  text-align: center;
}
.home_location_filter {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  padding: 0 20px;
}
.home_location_filter p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.filters_reset_cta {
  font-weight: 500;
  font-size: 14px;
  color: #194af5;
  cursor: pointer;
}
.home_location_filter .MuiSlider-thumbColorPrimary {
  color: #194af5;
}
.home_location_filter .css-1gv0vcd-MuiSlider-track {
  color: #d0d0d0;
  height: 2px;
}
.home_location_filter .MuiSlider-markLabel {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.home_price_filter p {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.sort_filter_label {
  font-weight: 500;
  font-size: 14px !important;
  color: #232526;
}
.price_filter_label {
  font-weight: 500;
  font-size: 14px !important;
  color: #232526;
}
.home_sort_filter p {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.home_speciality_filter .home_speciality_filter_list {
  max-height: 200px;
  overflow: scroll;
}

.home_speciality_filter div {
  margin-bottom: 10px;
}
.home_speciality_filter label {
  font-weight: 500;
  font-size: 14px;
  color: #232526;
}
.home_speciality_filter button {
  color: #194af5;
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 12px;
}
.home_speciality_filter .checkmark {
  margin-bottom: 5px;
  margin-right: 5px;
  width: 15px;
}
.home_speciality_filter p {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.home_filter_apply_btn {
  /* position: absolute; */
  /* bottom: 1vw; */
  margin: auto;
  padding: 2vw;
  width: 30vw;
  text-align: center;
  color: white;
  border-radius: 0.2rem;
  background-color: #194af5;
}
