/* health Page CSS */
.health_card_buttons button {
  border-radius: 3px;
  width: 150px;
  border: none;
  padding: 4px;
  font-size: 12px;
}
.health_card_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto 0px auto;
  width: 98%;
}
.health_card_button1 {
  background: #e6ebff !important;
  color: #194af5 !important;
}
.health_card_button2 {
  background: #194af5;
}

/* health Card CSS */
.health_card_box {
  /* background-color: rebeccapurple; */
  width: 98%;
  margin: auto;
  height: 220px;
  border: 5px solid #305098;
}
.health_card_header {
  height: 55px;
}

.health_card_header img {
  width: 100%;
}

.health_details_box {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  align-items: center;
  justify-items: center;
}
.health_user_info h6 {
  font-weight: 600;
  font-size: 12px;
  color: #13110c;
  margin-top: 15px;
}
.health_user_info_rows {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.health_user_info_rows p {
  font-size: 8px !important;
  margin-bottom: 5px;
  text-align: left;
  color: #000;
}
.health_user_image_1 {
  width: 80px;
  height: 90px;
  background-color: #d9d9d9;
  margin: 0 10px;
}
.health_user_image_1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.health_user_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
