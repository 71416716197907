#PaymentMethodHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}
.PaymentMethodCard {
  background-color: #fbfbfb;
  padding: 20px;
}
.PaymentMethodBtn {
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  color: #232526;
}
