#YourbookingPageHeadContainer {
  margin-bottom: 0.5rem;
  padding: 15px 0 0 0;
  background-color: #fff;
  position: sticky;
  top: 0%;
  z-index: 99;
}

#YourBookingPageHeadSvg {
  vertical-align: middle;
  margin-left: 3vw;
  margin-right: 2vw;
  cursor: pointer;
}
#YourbookingPageHead {
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  color: #000000;
}
.noBookings {
  margin: 15vh auto 0 auto;
  width: 90%;
  height: auto;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.noBookings_head {
  font-size: 5vw;
  font-weight: 700;
  color: #555555;
}
.noBookings_desc {
  width: 90%;
  margin: auto;
  margin-top: 8vh;
  font-size: 4.5vw;
  font-weight: 500;
  color: #6e6e6e;
}
.noBookings img {
  max-width: 60%;
  height: 100%;
  margin: auto;
}
.your_bookings_tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5em;
  width: 95%;
  margin: 1.5em auto;
  font-size: 16px;
}
.your_bookings_tab {
  background: #f4f4f4;
  border: 0.2px solid #7c7c7c;
  border-radius: 5px;
  color: #505050;
  text-align: center;
  padding: 0.4em 1em;
  font-size: 0.8em;
}
.your_bookings_tab.tab_active {
  border: 0.2px solid #194af5;
}
