.booking_page_modal_header {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #454545;
}
.booking_page_calender {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 1vw;
  right: 10vw;
  font-size: 4vw;
  font-weight: 500;
  color: #194af5;
}
.booking_page_calender svg {
  margin-top: 0;
  margin-bottom: 0.4rem;
}
.date_selector_wrapper {
  width: auto;
  min-width: 90vw;
  height: 15vh;
  overflow: scroll;
  display: inline-grid;
  /* grid-template-columns: repeat(5, 50%) ; */
}
.date_selector_wrapper::-webkit-scrollbar {
  display: none;
}
.radio_toolbar .booking_date_radio_input {
  /* display: flex; */

  opacity: 0;
  position: fixed;
  width: 0;
}
.radio_toolbar_slots .booking_date_radio_input {
  /* display: flex; */

  opacity: 0;
  position: fixed;
  width: 0;
}
.booking_page_slider_dates {
  display: flex;
  justify-content: space-around;
  width: 100vw;
  white-space: nowrap;
  overflow: scroll;
  scroll-behavior: smooth;
}
.booking_page_slider_dates::-webkit-scrollbar {
  display: none;
}
.radio_toolbar label {
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3vw 2vw;
  width: 18vw;

  /* max-width: 14vw; */
  text-align: center;
  background: #f7f8f9;
  border: 1px solid #9aa2b6;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 5vw;
  letter-spacing: 0.01em;
  color: #454545;
  margin: 10px 10px;
}
.radio_toolbar label:hover {
  background: #194af5;
  color: #e6ebff;
  border: 1px solid #194af5;
}
.day_input_label {
  min-width: 5vw;
  height: 28vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 3vw 2vw; */
  width: 20vw;
  text-align: center;
  /* background: #fff !important; */
  border: 1px solid #b5b5b5 !important;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 4.5vw;
  letter-spacing: 0.01em;
  /* color: #454545 !important; */
  margin: 1vw;
  /* position: relative; */
}
.today_text {
  position: absolute;
  top: -25%;
  left: -30%;
  margin-bottom: 5px;
}
.radio_toolbar label div {
  font-size: 3vw;
}
.booking_date_radio_input:checked + .date_input_label {
  background: #194af5;
  color: #e6ebff;
  border: 1px solid #194af5;
}
/* .date_input_label{
  width: 5vw;
  height: 6vw;
} */
.booking_date_radio_input:disabled {
  background: #f7f8f9 !important;
  color: #ddd !important;
}
.nofoundslotstext {
  font-size: 16px;
  font-weight: 500;
  /* line-height: 1px; */
  max-width: 200px;
  text-align: center;
}
.checkedSlot {
  background: #e6ebff !important;
  color: #194af5 !important;
  border: 1px solid #194af5 !important;
}

/* .radio_toolbar_slots label { */
.date_input_label {
  /* display: inline-block; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 2.5vw 2.5vw;
  min-width: 18vw;
  width: 90%;
  height: fit-content;
  /* max-height: 30%; */
  /* max-width: 14vw; */
  text-align: center;
  background: #ffffff;
  border: 1px solid #9aa2b6;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 3.52vw;
  letter-spacing: 0.01em;
  color: #454545;
  margin: 10px 10px;
}
/* .radio_toolbar_slots label:hover {
  background: #194AF5;
  color: #e6ebff;
  border: 1px solid #194af5;
} */
.radio_toolbar_slots label:checked {
  background: #194af5;
  color: #e6ebff;
  border: 1px solid #194af5;
}
.radio_toolbar_slots label:disabled {
  background: #454545;
}
.date_input_label_disabled {
  padding: 2vw 2vw;
  min-width: 18vw;
  width: 90%;
  height: fit-content;
  /* max-width: 14vw; */
  text-align: center;
  border: 1px solid #9aa2b6;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 3.52vw;
  letter-spacing: 0.01em;
  color: #454545;
  margin: 10px 10px;
  background: #f3f3f3;
}
.booking_appointment_cta {
  background-color: #194af5;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
  width: 90%;
  border: none;
  border-radius: 4px;
  margin-left: 4vw;
  margin-top: 30px;
}
.today_text {
  font-size: 12px;
}
