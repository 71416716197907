.PatientsDetailsForm_header {
  padding: 20px 0;
  width: 90%;
  margin: auto;
}
.PatientsDetailsForm_header h1 {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.PatientsDetailsForm_header p {
  font-weight: 400;
  font-size: 14px;
  color: #707272;
}
.backButton {
  width: 90%;
  margin: auto;
  padding: 10px 0;
  cursor: pointer;
}
.patient_details_inputs {
  width: 90%;
  margin: auto;
}
.patient_details_inputs label {
  display: block;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.04em;
  color: #000000;
  margin-bottom: 10px;
}
.input_icon_patient {
  position: absolute;
  top: 11px;
  left: 2%;
  width: 20px;
}
.input_icon_phone {
  color: #a4a4a4;
  font-size: 12px;
}
.patient_input_icon {
  position: relative;
}
.patient_input_icon::before {
  content: "";
  border-right: 1px solid #a4a4a4;
  top: 9px;
  height: 24px;
  position: absolute;
  left: 10%;
}

.patient_details_input {
  padding: 8px;
  border: 1px solid #a4a4a4;
  border-radius: 4px;
  padding-left: 50px;
  margin-bottom: 30px;
  width: 100%;
  font-size: 1rem;
  color: #232526;
}
.patient_details_input:focus {
  outline: 1px solid #194af5;
}
.patient_details_input::placeholder {
  font-size: 13px;
  color: #7d818b;
}
.gender_box {
  margin-bottom: 15px;
}
.gender_box .checkbox-tools:checked,
.checkbox-tools:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}
.checkbox-tools:checked + label,
.checkbox-tools:not(:checked) + label {
  color: #7d818b;

  position: relative;
  display: inline-block;
  padding: 7px;
  width: 80px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0 auto;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  /* text-transform: uppercase; */
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.checkbox-tools:not(:checked) + label {
  border: 1px solid #c4c4c4;
}
.checkbox-tools:checked + label {
  background-color: #194af5;
  color: #fff;
  border: 1px solid #194af5;
}
.checkbox-tools:not(:checked) + label:hover {
  background-color: #194af5;
  color: #fff;
}
.checkbox-tools:checked + label::before,
.checkbox-tools:not(:checked) + label::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: -1;
}
.patients_details_next_cta {
  width: 90%;
  margin: 0 auto;
}
.patients_details_next_cta button {
  background-color: #194af5;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
}
.patient_details_input input:invalid {
  border: 1px solid red;
}

/* input:valid {
  border: 2px solid black;
} */
