.app_bar {
    margin-top: 0;
    z-index: 3;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8.5vh;
    background: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;

    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.inactive {
    filter: grayscale(100%);
}
.app_bar_menu {
    text-decoration: none;
}
.app_bar_menu.active {
    border-bottom: 3px solid #194af5;
}
.app_bar_menu.active svg path {
    fill: #194af5;
}
.app_bar img {
    width: 40px;
    height: 30px;
    margin-top: 4px;
}
.app_bar p {
    font-weight: 500;
    font-size: 8px;
    color: #232526;
    text-align: center;
}
.app_bar .app_bar_menu {
    /* width: 80px; */
    height: 40px;
    text-align: center;
}
