#CheckOutPageHead {
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  background-color: #fff;
  padding: 15px 0;
  position: sticky;
  top: 0;
}
#CheckOutPageHeadSvg {
  margin-left: 3vw;
}
#div4Cards {
  margin-top: 1rem;
  background-color: #fbfbfb;
}

#PaymentBtn {
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
  margin-top: 3rem;
}
.checkout_cards {
  background-color: #fff;
}
