.UserInfoCardHeads {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  letter-spacing: 0.04em;
  margin-top: 1.3rem;
  color: #000000;
}
.UserInfoCardData {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875;
  line-height: 1.063rem;
  margin-top: 1rem;
  margin-top: 1.25rem;
  letter-spacing: 0.02em;
  color: #232526;
}
#ChangeDetailBtn {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: #194af5;
  margin-right: 1rem;
  padding-bottom: 10px;
}
