.TermsAndConditions_header {
  width: 90%;
  margin: auto;
  height: fit-content;
}
.TermsAndConditions_header h1 {
  font-weight: 600;
  font-size: 1.2em;
  color: #000000;
  cursor: pointer;
  padding-top: 20px;
}
.TermsAndConditions_content {
  width: 90vw;
  margin: 1em auto;
}
.TermsAndConditions_content h2 {
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 0.02em;
  color: #5c5c5c;
  padding: 0.8em 0;
}
.TermsAndConditions_content p {
  font-weight: 400;
  font-size: 0.9em;
  line-height: 18px;
  text-align: justify;
  color: #7d818b;
}
