.home_doctor_listing_content {
  margin-left: 10px;
}
.docter_listing_name {
  margin: 0 !important;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 8px 0;
}

.doctor_address {
  font-weight: 400;
  font-size: 10px;
  color: #3b466f;
}
.doctor_address svg {
  margin-right: 5px;
}

.home_kms_distance {
  background: #00bf91;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
}
.home_doctor_visit_info {
  display: flex;
}
