.CancellationInfoModal {
  position: absolute;
  right: 1em;
  top: 2em;
  background: #f4f4f4;
  border: 0.2px solid #7c7c7c;
  border-radius: 10px;
  width: 14em;
  padding: 1em;
}
#ReFundDivhead {
  font-weight: 500;
  font-size: 0.625rem;
  color: #505050;
}
#ReFundDivBottom {
  font-weight: 400;
  font-size: 0.6rem;
  line-height: 11px;
  color: #505050;
  margin-top: 1em;
}
