.maps_pointer {
  height: 67vh;
  background-color: #d7d7d7;
}
.select_location_title {
  padding: 10px;
  font-weight: 500;
  font-size: 0.8rem;
  letter-spacing: 0.04em;
  color: #717171;
  margin-bottom: 0;
}
.map_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
}
.map_box_title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.map_box_title h1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #4b4b4b;
}
.map_box_title p {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #8a8a8a;
  width: 80%;
  margin-bottom: 0;
}
.map_box button {
  background: #194af5;
  border-radius: 5px;
  font-size: 10px;
  border: none;
  color: #fff;
  font-weight: 500;
  padding: 5px 10px;
}
.confirm_cta {
  width: 80%;
  margin: auto;
}
.confirm_cta button {
  background: #194af5;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  color: #fff;
  font-weight: 500;
  width: 100%;
  padding: 7px 10px;
  margin-top: 10px;
}
.google_maps_back_cta {
  background-color: #fff;
  box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.2);
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 99;
}
