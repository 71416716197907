.profile_backButton {
  font-weight: 600;
  vertical-align: middle;
  font-size: 20px;
  margin-bottom: 30px;
  width: 90%;
  margin: auto;
  padding: 20px 0;
  cursor: pointer;
}
.profile_backButton svg {
  margin-right: 8px;
}
.profile_edit_cta {
  text-align: right;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
}
.profile_edit_cta button {
  background-color: #fff;
  border: none;
  color: #194af5;
  font-weight: 500;
}
.my_profile_inputs {
  width: 90%;
  margin: auto;
}
.my_profile_inputs label {
  margin-bottom: 8px;
}
.profile_details_next_cta {
  width: 90%;
  margin: auto;
}
.profile_details_next_cta button {
  width: 100%;
  background-color: #194af5;
  border: none;
  color: #fff;
  padding: 6px;
  border-radius: 6px;
  margin-top: 5vh;
}
.profle_gender {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  max-width: max-content;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 12px;
  color: #838383;
}

.gender_box {
  margin-bottom: 15px;
}
