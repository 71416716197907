.abdm_header p {
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #828282;
  max-width: 65%;
}
.aadharNum {
  color: #464646 !important;
  font-size: 14px !important;
}
.otp_timer {
  color: #7d818b !important;
  font-size: 14px;
}
.resend_cta {
  color: #194af5 !important;
  font-size: 12px;
  padding-left: 5px;
  font-weight: 500;
}
.change_aadhar__num {
  color: #194af5 !important;
  font-weight: 600 !important;
}
.otp_box {
  height: 70px;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.otp-field {
  width: 38px;
  height: 40px;
  background: #f2f2f2;
  box-shadow: 3.66102px 3.66102px 9.15254px rgba(0, 0, 0, 0.05);
  border-radius: 4.57627px;
  text-align: center;
  border: none;
}
