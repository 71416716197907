#BookingDoneSvg {
  margin-top: 16.223067173637517vh;
}
#BookingDoneHead {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 29px;
  color: #000000;
}
#BookingDoneSubHead {
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;
  color: #48484b;
  margin-top: 1.25rem;
}
#MyBookingBtn {
  font-weight: 500;
  font-size: 1em;
  background-color: #194af5;
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 6px;
}
