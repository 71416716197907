.login_screens h1 {
  font-weight: 500;
  font-size: 22px;
  color: #000;
  text-align: center;
  margin: 0;
}

.login_slider_logo img {
  max-width: 18%;
  padding: 12px 5px 0 10px;
}
.login_slider_card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.login_slider_card img {
  max-width: 60%;
  margin: 20px 0;
}
.login_slider_card h1 {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  width: 90%;
}
.number_input_form {
  margin: 50px auto 20px auto;
  width: 90%;
}
.number_input_form h2 {
  font-weight: 500;
  font-size: 14px;
  color: #838383;
  text-align: center;
  margin-bottom: 30px;
}
.login_input_icon {
  position: relative;
}

.login_details_input {
  padding: 6px;
  border: 0.510535px solid #e6ebed;
  border-radius: 10px;
  padding-left: 35px;
  margin-bottom: 40px;
  font-size: 1rem;
  color: #232526;
  width: 100%;
}
.login_details_input:focus {
  outline: 1px solid #194af5;
}
.login_details_input::placeholder {
  font-size: 12px;
  color: #7d818b;
}
.login_form_inut_cta {
  background-color: #194af5;
  border: none;
  color: #fff;
  width: 100%;
  font-weight: 500;
  padding: 6px;
  border-radius: 4px;
}
.login_screen_footer h6 {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: rgba(100, 116, 139, 0.8);
  width: 90%;
  margin: auto;
}
.login_screen_footer span {
  color: #194af5;
  cursor: pointer;
}
.otp_input_form {
  margin: 40px auto 20px auto;
  width: 90%;
}
.otp_input_form h2 {
  font-weight: 500;
  font-size: 14px;
  color: #838383;
  text-align: center;
  margin-bottom: 20px;
}
.otp_box {
  height: 70px;
  margin-top: 10px;
}

.login_otp_field {
  width: 38px;
  margin-right: 10px;
  padding-left: 12px;
  height: 40px;
  background: none;
  border-radius: 2px;
  border: none;
  border-bottom: 1.24015px solid #b7b7b7;
}
.change_num {
  color: #194af5;
  font-weight: 500;
  font-size: 12px;
  width: 100px;
  cursor: pointer;
}
.resend_otp_cta {
  color: #194af5;
}
.phone_number {
  font-weight: 500;
  font-size: 14px;
  color: #232526;
  margin: 0;
}
.otp_timer {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #7d818b;
  /* margin: 0; */
}
.otp_details svg {
  margin-right: 5px;
}
