.home_profile_header {
  padding: 20px;
  /* background-color: blue; */
}
.home_profile_header_address {
  /* width: 80%; */
}
.home_profile_header_address h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1em;
  color: #000000;
  word-wrap: break-word;
}
.home_profile_header_address p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 0.6em;
  color: #868686;
  word-wrap: break-word;
}
.home_search_input_container {
  width: 90%;
  margin: auto;
  position: relative;
}

.home_input_filter_btn {
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: #eef4ff;
  border: 0.2px solid #5686e1;
  border-radius: 2px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_search_input {
  padding: 10px;
  background-color: #f3f3f3 !important;
  border: 0.5px solid #e1e1e1;
  border-radius: 5px;
  background: url("../../assets/images/searchnormal1.svg") no-repeat;
  background-size: 18px;
  background-position: 10px 11px;
  padding-left: 40px;
  width: 100%;
  font-size: 0.9em;
}
.home_search_input::placeholder {
  font-size: 0.9em;
}
.install_pwa {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #194af5;
  border-radius: 5px;
  padding: 7px 10px 7px 15px;
  font-weight: 600;
  font-size: 3.2vw;
  color: #fff;
  z-index: 3;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
}

.install_pwa_cta {
  padding: 0.7vw 4vw;
  color: #194af5;
  background: #ffffff;
  border-radius: 3px;
  border: none;
  font-weight: 600;
}
.abha_card {
  background: #eef4ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 90%;
  margin: auto;
  margin-top: 1rem;
}
.abha_card img {
  max-width: 15%;
  margin-right: 10px;
}
.abha_card h2 {
  font-weight: 500;
  font-size: 12px;
  color: #454545;
  margin-bottom: 5px;
}
.abha_card p {
  font-weight: 500;
  font-size: 10px;
  color: #828282;
  margin-bottom: 0;
}
.home_video {
  width: 90%;
  margin: 1rem auto;
  display: flex;
  background-color: #e5edff;
  border-radius: 0.5rem;
  padding: 4vw;
}
.home_video img {
  /* height: fit-content; */
  max-width: 100%;
  /* margin: auto; */
}
.home_video_consult {
  width: 70%;
}
.home_video_consult_head {
  font-size: 4vw;
  font-weight: 600;
  margin-bottom: 1vw;
}
.home_video_consult_desc {
  font-size: 2.5vw;
  font-weight: 500;
  color: #828282;
}
.home_video_consult_cta {
  background: rgba(25, 74, 245, 0.8);
  border: 0.437044px solid #5d78d6;
  border-radius: 2px;
  font-weight: 500;
  width: 23vw;
  height: auto;
  text-align: center;
  font-size: 12px;
  padding: 2px;
  color: #ffffff;
  margin-top: 1.5vh;
}
.home_video_consult_cta svg {
  margin-left: 5vw;
}
.home_video_consultation_card {
  background: #e6edff;
  border-radius: 5px;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.home_video_consultation_card h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #515151;
}
.home_video_consultation_card p {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #828282;
}
.home_video_consultation_card img {
  max-width: 100%;
  height: 80px;
}
.home_video_consultation_card button {
  background: rgba(25, 74, 245, 0.8);
  border-radius: 2px;
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  border: none;
  padding: 6px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home_feature_docters_header {
  width: 90%;
  margin: 25px auto 10px auto;
  font-weight: 700;
  font-size: 15.5px !important;
  color: #2e2e2e;
}
.home_docter_card {
  background: #fff;
  box-shadow: 8px 12px 20px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 8px;
  max-width: 160px;
  margin: 0 0px 10px 15px;
}
.home_doctor_card_img {
  background: #9fd7ef;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.home_doctor_card_img img {
  max-width: 60%;
}
.home_feature_docter_card_name {
  font-weight: 600;
  font-size: 12px;
  /* max-width: 60%; */
  color: #000000;
  margin: 0 !important;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.home_docter_card h6 {
  font-weight: 500;
  font-size: 10px;
  color: #505050;
  margin-bottom: 5px;
}
.home_docter_card p {
  font-weight: 500;
  font-size: 10px;
  color: #505050;
  margin-bottom: 0;
}
.home_docter_rating {
  background: #2fbf4f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 8px;
  color: #ffffff;
  width: 50px;
  height: 20px;
}
.home_docter_rating svg {
  margin-right: 5px;
  width: 10px;
}
.docter_listing_card_rating {
  background: #2fbf4f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  padding: 6px;
  height: 20px;
}

.docter_listing_card_rating svg {
  margin-right: 5px;
  margin-bottom: 3px;
}

.home_doctor_listing_card {
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 6px;
  width: 95vw;
  margin: 10px auto;
  padding: 10px;
  display: flex;
}

.doctor_listing_card_img {
  background: #9fd7ef;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100px;
  height: 100px;

}
.doctor_listing_card_img img {
  max-width: 100%;
}
.home_doctor_listing_content {
  margin-left: 10px;
}
.docter_listing_name {
  margin: 0 !important;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 8px 0;
  text-transform: capitalize;
}
.docter_speciality_and_exp {
  font-weight: 500;
  font-size: 0.7em;
  color: #505050;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0px;
}

.exp_content {
  margin-left: 5px;
}

.doctor_address {
  font-weight: 400;
  font-size: 10px;
  color: #3b466f;
}
.doctor_address svg {
  margin-right: 5px;
}
.home_consultation_fee {
  background: #009dbf;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  margin-right: 10px;
  height: 20px;
}

.home_consultation_fee svg {
  margin-right: 5px;
  margin-bottom: 3px;
  /* width: 14px; */
}
.home_kms_distance {
  background: #00bf91;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  height: 20px;
}
.home_doctor_visit_info {
  display: flex;
}
.filter_chips {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.filter_chips button {
  text-align: center;
  background: rgba(25, 74, 245, 0.8);
  border: 0.437044px solid #5d78d6;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  padding: 2px 8px;
  color: #ffffff;
  position: relative;
  margin: 5px;
}
.filter_chips svg {
  margin-left: 5px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 6px;
  right: 5px;
}
.home_login_button {
  background: rgba(25, 74, 245, 0.8);
  border: 0.437044px solid #5d78d6;
  border-radius: 2px;
  font-weight: 500;
  width: 25vw;
  height: auto;
  text-align: center;
  font-size: 12px;
  padding: 2px;
  color: #ffffff;
}
.home_doctor_sign_up_button {
  background: white;
  border: 0.437044px solid #5d78d6;
  border-radius: 2px;
  font-weight: 500;
  width: 25vw;
  height: auto;
  text-align: center;
  font-size: 12px;
  padding: 2px;
  color: rgba(25, 74, 245, 0.8);
}
.location_set_name {
  color: #828282;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}
.location_set_input {
  width: 90%;
  height: 10vw;
  background-color: #f3f3f3;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  padding: 3vw;
  font-size: 3vw;
  margin-top: 50px;
}
.location_set_current {
  font-size: 4vw;
  font-weight: 600;
  letter-spacing: 0.3vw;
}
.location_recent {
  width: 80%;
  margin: auto;
}
.location_recent p {
  font-size: 4.5vw;
  font-weight: 700;
  color: #3a3a3a;
}
.location_recent_head {
  font-size: 4vw;
  font-weight: 500;
}
.location_recent_desc {
  font-size: 3vw;
  font-weight: 400;
  color: #8a8a8a;
}
.divider {
  height: 0.07px;
  width: 100%;
  margin: 15px 0 0px 0;
  background-color: #c2c2c2;
}
@media only screen and (max-width: 360px) {
  .home_docter_card h1 {
    flex-direction: column;
    align-items: flex-start;
  }
}
.search_modal_input_container {
  width: 90%;
  margin: auto;
}


