.patient_type_chip {
  background: #5399b8;
  border-radius: 2px;
  border: none;
  padding: 3px 10px;
  color: #fff;
  font-weight: 500;
  font-size: 7px;
  margin-left: 10px;
  vertical-align: middle;
}
.appointment_bookings_card {
  background: #ffffff;
  box-shadow: 8px 16px 60px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 95%;
  margin: 20px auto;
  position: relative;
}
.info_modal_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 1em;
  cursor: pointer;
}
.OnGoingparaLine {
  font-weight: 500;
  font-size: 0.625rem;
  width: 100%;
  color: #505050;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.YourBooking_home_doctor_listing_card {
  width: 90%;
  margin: 20px auto;
  padding: 10px 0px;
  display: flex;
}

.home_doctor_listing_content {
  margin-left: 10px;
}

.docter_listing_name {
  margin: 0 !important;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 8px 0;
}

.Yourbooking_doctor_address {
  font-size: 9px;
  color: #2755f6;
}
.Yourbooking_doctor_address svg {
  margin-right: 5px;
}
.home_kms_distance {
  background: #00bf91;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
}

.home_doctor_visit_info {
  display: flex;
}

#CancelAppointmentBtn {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  background-color: transparent;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  color: #be2200;
  border: none;
  border-radius: 0;
  padding: 5px 5px 15px 5px;
}
#DownloadPrescriptionBtn {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  background-color: transparent;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  color: #194af5;
  border: none;
  border-radius: 0;
  padding: 10px 5px;
}

#YourBookingsCardBookDateNtimeHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}

.YourBookingsCardCalanderNdate {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  color: #48484b;
}

#YourBookingsCardDateDiv {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #48484b;
}

#YourBookingsCardTimeDiv {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #48484b;
}

#YourBookingsCardChangeDateNtime {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #194af5;
}

#AppointmentType {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.357rem;
  color: #ffffff;
  padding: 0.20833333333333334vw 1.267427122940431vh;
  background: #5399b8;
  margin-left: 0.5rem;
  border-radius: 2px;
}

#YellowBgBtn {
  color: #c2c542;
  background-color: #feffcc;
  border-color: #feffcc;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
}
#GreenBgBtn {
  color: #2fbf4f;
  background-color: rgba(47, 191, 79, 0.3);
  border-color: rgba(47, 191, 79, 0.3);
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
}
#RedBgBtn {
  color: #d75c17;
  background-color: #ffe1d0;
  border-color: #ffe1d0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
}

#OnGoingConsultation {
  width: 50vw;
}

.view_invoice_cta {
  background: rgba(47, 191, 79, 0.3);
  border-radius: 8.4375px;
  font-weight: 500;
  font-size: 0.8em;
  color: #2fbf4f;
  border: none;
  padding: 5px;
}
.prescription_download_container {
  position: absolute;
  left: -999px;
}
